import React, { useEffect, useContext } from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from 'tss-react/mui'
import useMediaQuery from '@mui/material/useMediaQuery'
import { AppContext } from '../../context/AppState'
// import { useTranslation } from 'react-i18next'
import { domainToLogos } from '../../domainToLogos'
// core components
import Dropdown from 'components/CustomDropdown/CustomDropdown.js'

import { isLocalhost, domain } from 'serviceWorker'
import Parallax from 'components/Parallax/Parallax.js'
// import RegularButton from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
// import CardHeader from 'components/Card/CardHeader.js'
// import CardBody from 'components/Card/CardBody.js'
// import Menu from 'components/Menu/Menu'
import NotFound from 'components/NotFound/NotFound'
import presentationStyle from 'assets/jss/material-kit-pro-react/views/presentationStyle.js'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Box } from '@mui/material'
import { useParams } from 'react-router'

import CompanyInfo from 'components/CompanyInfo/CompanyInfo.js'
import CompanyInfoMobile from 'components/CompanyInfo/CompanyInfoMobile.js'

import CertificationsTable from 'components/CertificationsTable/CertificationsTable.js'
import AllergenesTable from 'components/AllergenesTable/AllergenesTable.js'
import Traceability from 'components/Traceability/Traceability.js'
import Disposal from 'components/Disposal/Disposal.js'
import ProductPage from 'components/ProductPages/ProductPage'
import CertDialog from 'components/CertDialog/CertDialog.js'
import DescriptionDialog from 'components/DescriptionDialog/DescriptionDialog.js'
import DocumentsDialog from 'components/DocumentsDialog/DocumentsDialog.js'
// import SocialLinks from 'components/SocialLinks/SocialLinks.js'
import LanguageIcon from '@mui/icons-material/Language'

import Avatar from '@mui/material/Avatar'
// import BuyNowModal from 'components/BuyNowModal/BuyNowModal.js'

import {
  getProduct,
  getDiscovers,
  getLots,
  getLightLots,
  getCompany,
  populateLotData,
  populateProductData,
  getFacilities
} from '../../actions/AppActions'
// import { changeLanguage } from 'utils/translations'
// import CompanyVerificationBanner from 'components/CompanyVerificationBanner'
import { returnAbbr, changeLanguage, currLang } from 'utils/translations'
import PresentationPageMobile from './PresentationPageMobile'
import PresentationPageDesktop from './PresentationPageDesktop'
import LogoLoader from 'components/LogoLoaderImage/index'
import { alog } from 'utils/apioLog'
import { hideOrShowProperty } from '../../utils/utils'

const useStyles = makeStyles()(presentationStyle)

// main hook
export default function PresentationPage() {
  // const { t } = useTranslation('presentationPage')
  // const [openBuyNow, setOpenBuyNow] = React.useState(false)

  // React-router hook per prendere i parametri passati nell'url (uuid o gtin prodotto, uuid o lotNumber del lotto)
  const { prod, lot } = useParams()
  // stato globale dell'app e funzione che fa il dispatch delle azioni

  // NOTE: this switch is needed for zaccagni and meracinque in order to redirect to the right page (new platform)
  switch (prod) {
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000073': {
      window.location.replace(
        'https://engage.trusty.id/01/6f091109-4f54-455b-b0c7-7e9af67c3243-p00000073'
      )
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000072': {
      window.location.replace(
        'https://engage.trusty.id/01/6f091109-4f54-455b-b0c7-7e9af67c3243-p00000072'
      )
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000074': {
      window.location.replace(
        'https://engage.trusty.id/01/6f091109-4f54-455b-b0c7-7e9af67c3243-p00000074'
      )
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000075': {
      window.location.replace(
        'https://engage.trusty.id/01/6f091109-4f54-455b-b0c7-7e9af67c3243-p00000075'
      )
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000062': {
      window.location.replace('https://engage.trusty.id/01/8059020240803')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000004': {
      window.location.replace('https://engage.trusty.id/01/8059020240063')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000029': {
      window.location.replace('https://engage.trusty.id/01/8059020240292')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000030': {
      window.location.replace('https://engage.trusty.id/01/8059020240278')
      break
    }
    // NOTE: i capunti sembrano non essere online sulla nuova piattaforma

    // case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000081' : {
    //   window.location.replace('https://engage.trusty.id/01/6f091109-4f54-455b-b0c7-7e9af67c3243-p00000075')
    //   break
    // }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000035': {
      window.location.replace('https://engage.trusty.id/01/8059020240773')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000056': {
      window.location.replace('https://engage.trusty.id/01/8059020240834')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000020': {
      window.location.replace('https://engage.trusty.id/01/8059020240155')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000080': {
      window.location.replace('https://engage.trusty.id/01/8059020240414')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000063': {
      window.location.replace('https://engage.trusty.id/01/8059020240797')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000016': {
      window.location.replace('https://engage.trusty.id/01/8059020240056')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000031': {
      window.location.replace('https://engage.trusty.id/01/8059020240261')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000065': {
      window.location.replace('https://engage.trusty.id/01/8059020240209')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000042': {
      window.location.replace('https://engage.trusty.id/01/8059020240735')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000052': {
      window.location.replace('https://engage.trusty.id/01/8059020240865')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000014': {
      window.location.replace('https://engage.trusty.id/01/8059020240070')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000082': {
      window.location.replace(
        'https://engage.trusty.id/01/6f091109-4f54-455b-b0c7-7e9af67c3243-p00000082'
      )
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000045': {
      window.location.replace('https://engage.trusty.id/01/8059020240766')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000049': {
      window.location.replace('https://engage.trusty.id/01/8059020240933')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000032': {
      window.location.replace('https://engage.trusty.id/01/8059020240308')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000021': {
      window.location.replace('https://engage.trusty.id/01/8059020240148')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000055': {
      window.location.replace('https://engage.trusty.id/01/8059020240841')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000061': {
      window.location.replace('https://engage.trusty.id/01/8059020240872')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000015': {
      window.location.replace('https://engage.trusty.id/01/8059020240049')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000038': {
      window.location.replace('https://engage.trusty.id/01/8059020240216')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000040': {
      window.location.replace('https://engage.trusty.id/01/8059020240230')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000022': {
      window.location.replace('https://engage.trusty.id/01/8059020240087')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000046': {
      window.location.replace('https://engage.trusty.id/01/8059020240759')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000076': {
      window.location.replace(
        'https://engage.trusty.id/01/6f091109-4f54-455b-b0c7-7e9af67c3243-p00000076'
      )
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000023': {
      window.location.replace('https://engage.trusty.id/01/8059020240124')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000079': {
      window.location.replace('https://engage.trusty.id/01/8059020240421')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000033': {
      window.location.replace('https://engage.trusty.id/01/8059020240285')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000067': {
      window.location.replace('https://engage.trusty.id/01/8059020240186')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000071': {
      window.location.replace(
        'https://engage.trusty.id/01/6f091109-4f54-455b-b0c7-7e9af67c3243-p00000071'
      )
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000024': {
      window.location.replace('https://engage.trusty.id/01/8059020240117')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000047': {
      window.location.replace('https://engage.trusty.id/01/8059020240742')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000054': {
      window.location.replace('https://engage.trusty.id/01/8059020240810')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000048': {
      window.location.replace('https://engage.trusty.id/01/8059020240926')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000025': {
      window.location.replace('https://engage.trusty.id/01/8059020240094')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000034': {
      window.location.replace('https://engage.trusty.id/01/8059020240100')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000070': {
      window.location.replace(
        'https://engage.trusty.id/01/6f091109-4f54-455b-b0c7-7e9af67c3243-p00000070'
      )
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000059': {
      window.location.replace('https://engage.trusty.id/01/8059020240780')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000026': {
      window.location.replace('https://engage.trusty.id/01/8059020240131')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000037': {
      window.location.replace('https://engage.trusty.id/01/8059020240254')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000027': {
      window.location.replace('https://engage.trusty.id/01/8059020240162')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000064': {
      window.location.replace('https://engage.trusty.id/01/8059020240827')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000043': {
      window.location.replace('https://engage.trusty.id/01/8059020240728')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000053': {
      window.location.replace('https://engage.trusty.id/01/8059020240858')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000050': {
      window.location.replace('https://engage.trusty.id/01/8059020240919')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000017': {
      window.location.replace('https://engage.trusty.id/01/8059020240032')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000018': {
      window.location.replace('https://engage.trusty.id/01/8059020240025')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000044': {
      window.location.replace('https://engage.trusty.id/01/8059020240711')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000019': {
      window.location.replace('https://engage.trusty.id/01/8059020240017')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000068': {
      window.location.replace('https://engage.trusty.id/01/8059020240315')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000041': {
      window.location.replace('https://engage.trusty.id/01/8059020240223')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000069': {
      window.location.replace('https://engage.trusty.id/01/8059020240193')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000078': {
      window.location.replace('https://engage.trusty.id/01/8059020240445')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000039': {
      window.location.replace('https://engage.trusty.id/01/8059020240247')
      break
    }
    case '6f091109-4f54-455b-b0c7-7e9af67c3243-p00000028': {
      window.location.replace('https://engage.trusty.id/01/8059020240179')
      break
    }
    case '8053435250085': {
      window.location.replace('https://engage.trusty.id/01/8053435250085')
      break
    }
    case '8053435250078': {
      window.location.replace('https://engage.trusty.id/01/8053435250078')
      break
    }
    case '8053435250092': {
      window.location.replace('https://engage.trusty.id/01/8053435250092')
      break
    }
    case '6d9f735e-4acf-41e1-b8cf-62f04695c367-p00000004': {
      window.location.replace(
        'https://discover.trackit.trusty.id/01/faf59b87-7265-4908-b2dd-ac399f95341d-p00000001'
      )
      break
    }
    case '62680d22-788a-4b46-b9ad-3f39696a640f-p00000001': {
      window.location.replace(
        'https://discover.trackit.trusty.id/01/62680d22-788a-4b46-b9ad-3f39696a640f-p00000020'
      )
      break
    }
    case '62680d22-788a-4b46-b9ad-3f39696a640f-p00000011': {
      window.location.replace(
        'https://discover.trackit.trusty.id/01/62680d22-788a-4b46-b9ad-3f39696a640f-p00000019'
      )
      break
    }
    case '8033049830675': {
      window.location.replace('https://engage.trusty.id/01/8033049830675');
      break;
    }
    case '8033049830668': {
      window.location.replace('https://engage.trusty.id/01/8033049830668');
      break;
    }
    case '8033049830651': {
      window.location.replace('https://engage.trusty.id/01/8033049830651');
      break;
    }
    case '8033049830644': {
      window.location.replace('https://engage.trusty.id/01/8033049830644');
      break;
    }
    case '8033049830637': {
      window.location.replace('https://engage.trusty.id/01/8033049830637');
      break;
    }
    case '8033049830620': {
      window.location.replace('https://engage.trusty.id/01/8033049830620');
      break;
    }
    case '8033049830613': {
      window.location.replace('https://engage.trusty.id/01/8033049830613');
      break;
    }
    case '8033049830606': {
      window.location.replace('https://engage.trusty.id/01/8033049830606');
      break;
    }
    case '8054729202469': {
      window.location.replace('https://engage.trusty.id/01/8054729202469');
      break;
    }
    case '8054729202483': {
      window.location.replace('https://engage.trusty.id/01/8054729202483');
      break;
    }
    case '8054729202490': {
      window.location.replace('https://engage.trusty.id/01/8054729202490');
      break;
    }
    case '8054729202506': {
      window.location.replace('https://engage.trusty.id/01/8054729202506');
      break;
    }
    case '8054729202513': {
      window.location.replace('https://engage.trusty.id/01/8054729202513');
      break;
    }
    case '8054729202025': {
      window.location.replace('https://engage.trusty.id/01/8054729202025');
      break;
    }
    case '8054729201790': {
      window.location.replace('https://engage.trusty.id/01/8054729201790');
      break;
    }
    case '8054729201226': {
      window.location.replace('https://engage.trusty.id/01/8054729201226');
      break;
    }
    case '8054729209734': {
      window.location.replace('https://engage.trusty.id/01/8054729209734');
      break;
    }
    case '8054729200243': {
      window.location.replace('https://engage.trusty.id/01/8054729200243');
      break;
    }
    case '8054729200076': {
      window.location.replace('https://engage.trusty.id/01/8054729200076');
      break;
    }
    case '8054729200274': {
      window.location.replace('https://engage.trusty.id/01/8054729200274');
      break;
    }
    case '8054729200397': {
      window.location.replace('https://engage.trusty.id/01/8054729200397');
      break;
    }
    case '8054729209833': {
      window.location.replace('https://engage.trusty.id/01/8054729209833');
      break;
    }
    case '8054729209727': {
      window.location.replace('https://engage.trusty.id/01/8054729209727');
      break;
    }
    case '8054729209673': {
      window.location.replace('https://engage.trusty.id/01/8054729209673');
      break;
    }
    case '8054729209680': {
      window.location.replace('https://engage.trusty.id/01/8054729209680');
      break;
    }
    case '8054729209703': {
      window.location.replace('https://engage.trusty.id/01/8054729209703');
      break;
    }
    case '8054729209697': {
      window.location.replace('https://engage.trusty.id/01/8054729209697');
      break;
    }
    case '8017596115867': {
      window.location.replace('https://engage.trusty.id/01/8017596115867');
      break;
    }
    case '8017596115850': {
      window.location.replace('https://engage.trusty.id/01/8017596115850');
      break;
    }
    case '8056326720902': {
      window.location.replace('https://engage.trusty.id/01/8056326720902');
      break;
    }
    case '8056326720858': {
      window.location.replace('https://engage.trusty.id/01/8056326720858');
      break;
    }
    case '8056326720889': {
      window.location.replace('https://engage.trusty.id/01/8056326720889');
      break;
    }
    case '8056326720872': {
      window.location.replace('https://engage.trusty.id/01/8056326720872');
      break;
    }
    case '8056326720896': {
      window.location.replace('https://engage.trusty.id/01/8056326720896');
      break;
    }
    case '8054729201233': {
      window.location.replace('https://engage.trusty.id/01/8054729201233');
      break;
    }
    default: {
      break
    }
  }
  const { state, dispatch } = useContext(AppContext)
  const {
    sectionActive,
    product,
    informationFound,
    informationLoaded,
    company,
    discover
  } = state

  alog('isLocalHost', isLocalhost, 'report')
  const domainSplitted = domain.split('.')
  const [languageSelected, setLanguageSelected] = React.useState(
    returnAbbr(currLang)
  )
  alog('domain', domain.domain, 'domain', 'ok')
  // funzione che controlla il link al social e se manca l'http lo aggiunge
  const checkHttpInSocialLinks = (socialLinks) => {
    Object.keys(socialLinks).forEach((social) => {
      if (
        socialLinks[social] &&
        !socialLinks[social].startsWith('http://') &&
        !socialLinks[social].startsWith('https://')
      ) {
        socialLinks[social] = `http://${socialLinks[social]}`
      }
    })
    return socialLinks
  }

  // funzione che scarica i dati del prodotto scansionato e della sua azienda produttrice
  async function fetchProducts() {
    try {
      let product = await getProduct(prod)
      const company = await getCompany(product.companyId)
      const facilities = await getFacilities(company.organizationId)

      product = await populateProductData(product)

      if (product && product.social) {
        const { website, ...social } = checkHttpInSocialLinks({
          ...product.social,
          website: product.website
        })
        product.social = social
        product.website = website
      }

      dispatch({
        type: 'SET_PRODUCT',
        payload: product
      })
      dispatch({
        type: 'SET_COMPANY',
        payload: company
      })
      dispatch({
        type: 'SET_FACILITIES',
        payload: facilities
      })
      dispatch({
        type: 'SET_INFORMATIONLOADED',
        payload: true
      })
    } catch (e) {
      dispatch({
        type: 'SET_INFO_FOUND',
        payload: false
      })
    }
  }

  async function fetchDiscoverResource(params) {
    try {
      const discover = await getDiscovers(params)

      dispatch({
        type: 'SET_DISCOVER',
        payload: discover
      })
    } catch (e) {
      alog('No hiding', 'DEBUG', 'info')
    }
  }

  // funzione che cambia lingua in base all'elemento selezionato
  const selectLanguage = (label) => {
    switch (label) {
      case 'ITA': {
        changeLanguage('it-IT')
        setLanguageSelected(returnAbbr('it-IT'))
        break
      }
      case 'ENG': {
        changeLanguage('en-US')
        setLanguageSelected(returnAbbr('en-US'))
        break
      }
      case 'FRA': {
        changeLanguage('fr-FR')
        setLanguageSelected(returnAbbr('fr-FR'))
        break
      }
      default: {
        changeLanguage('it-IT')
        setLanguageSelected(returnAbbr('it-IT'))
        break
      }
    }
  }

  // funzione che fa il fetch di prodotto e lotto e setta i dati per le pagine
  async function fetchLots() {
    try {
      let lots = await getLots(prod, lot)
      // Se il lotto esiste, allora popolo i campi e li mostro, altrimenti mostro il prodotto con schermata di lotto non trovato
      if (Object.entries(lots).length > 0) {
        // const expirationDate = lots.expirationDate
        lots = await populateLotData(lots)
        // dispatch({
        //   type: 'SET_EXPIRATION_DATE',
        //   payload: expirationDate
        // })
        dispatch({
          type: 'SET_LOT',
          payload: lots
        })
        dispatch({
          type: 'SET_LOT_LOADED',
          payload: true
        })

        dispatch({
          type: 'SET_INFORMATIONLOADED',
          payload: true
        })
      } else {
        dispatch({
          type: 'SET_LOT_NOT_FOUND',
          payload: true
        })
      }
    } catch (e) {
      dispatch({
        type: 'SET_LOT_NOT_FOUND',
        payload: true
      })
    }
  }

  // funzione che fa il fetch di prodotto e lotto e setta i dati per le pagine
  async function fetchLightLots() {
    try {
      let lots = await getLightLots(prod, lot)
      // Se il lotto esiste, allora popolo i campi e li mostro, altrimenti mostro il prodotto con schermata di lotto non trovato
      if (Object.entries(lots).length > 0) {
        // const expirationDate = lots.expirationDate
        lots = await populateLotData(lots)
        console.log('lots:::::::::::::::::::::', lots)
        // dispatch({
        //   type: 'SET_EXPIRATION_DATE',
        //   payload: expirationDate
        // })
        dispatch({
          type: 'SET_LIGHT_LOT',
          payload: lots
        })
        dispatch({
          type: 'SET_LIGHT_LOT_LOADED',
          payload: true
        })

        dispatch({
          type: 'SET_INFORMATIONLOADED',
          payload: true
        })
      } else {
        dispatch({
          type: 'SET_LIGHT_LOT_NOT_FOUND',
          payload: true
        })
      }
    } catch (e) {
      dispatch({
        type: 'SET_LIGHT_LOT_NOT_FOUND',
        payload: true
      })
    }
  }

  // Custom hook che fa il fetch dei dati in base ai parametri ricevuti nell'url
  const useFetchData = () => {
    useEffect(() => {
      fetchProducts()
      fetchDiscoverResource({ gtin: prod })
      if (!lot) {
        dispatch({
          type: 'SET_MENU_SECTION',
          payload: 1
        })
      } else {
        fetchLots()
        fetchLightLots()
        dispatch({
          type: 'SET_MENU_SECTION',
          payload: 5
        })
        dispatch({
          type: 'SET_URL_HAS_LOT',
          payload: true
        })
        dispatch({
          type: 'SET_LOT_NUMBER',
          payload: lot
        })
      }
    }, [])
  }

  // chiamata all'hook che fa il fetch dei dati
  useFetchData()

  useEffect(() => {}, [])
  // definisco stili e media query
  const { classes } = useStyles()
  const isSmall = useMediaQuery('(max-width: 600px)')

  const changeContentForLocalhost = (isLocalhost) => {
    if (isLocalhost) {
      return (
        <div
          style={{
            position: isSmall ? 'fixed' : 'absolute',
            top: 0,
            right: isSmall ? '20px' : '60px',
            zIndex: '999'
          }}
        >
          {/* <RegularButton color="warning"
                  onClick={() => setOpenBuyNow(true)}
                   round>{t('buyNow')}
                   </RegularButton> */}

          <Dropdown
            buttonText={languageSelected}
            className='fredoka'
            buttonProps={{
              color: 'ctaButton',
              startIcon: <LanguageIcon />
            }}
            dropdownList={['ITA', 'ENG', 'FRA']}
            onClick={(e) => selectLanguage(e)}
          />
        </div>
      )
    } else {
      if (Array.isArray(domainSplitted) && domainSplitted.length > 0) {
        if (domainSplitted[1] !== 'trusty') {
          return (
            <div
              style={{
                position: isSmall ? 'fixed' : 'absolute',
                top: 5,
                right: isSmall ? '20px' : '60px',
                zIndex: '999'
              }}
            >
              <a
                href={`${domainToLogos[domainSplitted[1]].website}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Avatar
                  className={classes.roundedAvatar}
                  alt={`${domainSplitted[1]} logo`}
                  src={`${
                    domainToLogos[domainSplitted[1]].sidebarLogos[0].img
                  }`}
                />
              </a>
            </div>
          )
        } else {
          return (
            <div
              style={{
                position: isSmall ? 'fixed' : 'absolute',
                top: 0,
                right: isSmall ? '20px' : '60px',
                zIndex: '999'
              }}
            >
              {/* <RegularButton color="warning"
            onClick={() => setOpenBuyNow(true)}
            round>{ t('buyNow')}
            </RegularButton> */}

              <Dropdown
                buttonText={languageSelected}
                className='fredoka'
                buttonProps={{
                  color: 'ctaButton',
                  startIcon: <LanguageIcon />
                }}
                dropdownList={['ITA', 'ENG', 'FRA']}
                onClick={(e) => selectLanguage(e)}
              />
            </div>
          )
        }
      } else {
        return (
          <div
            style={{
              position: isSmall ? 'fixed' : 'absolute',
              top: 0,
              right: isSmall ? '20px' : '60px',
              zIndex: '999'
            }}
          >
            {/* <RegularButton color="warning"
                  onClick={() => setOpenBuyNow(true)}
                   round>{t('buyNow')}
                  </RegularButton> */}

            <Dropdown
              buttonText={languageSelected}
              className='fredoka'
              buttonProps={{
                color: 'ctaButton',
                startIcon: <LanguageIcon />
              }}
              dropdownList={['ITA', 'ENG', 'FRA']}
              onClick={(e) => selectLanguage(e)}
            />
          </div>
        )
      }
    }
  }
  // NOTE: quando non viene trovato il prodotto.
  if (!informationFound) {
    return <NotFound />
  } else {
    if (!informationLoaded) {
      return <LogoLoader size='large'></LogoLoader>
    } else {
      return (
        <SkeletonTheme color='#ddebdd' highlightColor='#d3e3d3'>
          {changeContentForLocalhost(isLocalhost)}
          <div>
            {/* dialog track documents */}
            <DocumentsDialog />
            {/* dialog track description */}
            <DescriptionDialog />
            {/* dialog certifications mobile */}
            <CertDialog />
            {/* content */}
            <div
              style={{
                minHeight: '98vh',
                backgroundColor: isSmall ? '#fafafa' : ''
              }}
            >
              {hideOrShowProperty(discover, 'backgroundImage', 'product') &&
              product &&
              product.backgroundImage &&
              product.backgroundImage.cropped ? (
                <Parallax
                  style={{
                    height: isSmall ? '30vh' : '60vh',
                    display:
                      isSmall &&
                      sectionActive !== 1 &&
                      sectionActive !== 2 &&
                      sectionActive !== 3 &&
                      sectionActive !== 4 &&
                      sectionActive !== 5 &&
                      sectionActive !== 6
                        ? 'none'
                        : ''
                  }}
                  image={product.backgroundImage.cropped}
                  className={classes.parallax}
                ></Parallax>
              ) : (
                <Box id='skeleton-background'>
                  <Skeleton
                    duration={0.7}
                    height={isSmall ? '30vh' : '60vh'}
                    width='100%'
                  />
                </Box>
              )}
              <div
                className={
                  isSmall ? classes.cardContainerMobile : classes.cardContainer
                }
              >
                <Card
                  className={classNames(classes.main, classes.mainRaised)}
                  style={
                    isSmall
                      ? {
                          marginLeft: '0px',
                          marginRight: '0px',
                          borderRadius: '0px',
                          boxShadow: sectionActive !== 1 ? 'none' : ''
                        }
                      : {}
                  }
                >
                  {isSmall ? (
                    <PresentationPageMobile
                      product={product}
                      company={company}
                      sectionActive={sectionActive}
                    />
                  ) : (
                    <PresentationPageDesktop product={product} />
                  )}
                  {/* nel caso dello small è già messo dentro al PresentationPageMobile, per dare un impilazione diversa */}
                  {sectionActive === 1 && !isSmall ? (
                    <>
                      <ProductPage mobile={false} />
                    </>
                  ) : sectionActive === 2 ? (
                    !isSmall ? (
                      <CompanyInfo />
                    ) : (
                      <CompanyInfoMobile />
                    )
                  ) : sectionActive === 3 ? (
                    // NOTE: l'hide per certifications, allergenes e traceability è messo direttamente nel caricamento delle relative icone sul menu
                    <CertificationsTable />
                  ) : sectionActive === 4 ? (
                    <AllergenesTable />
                  ) : sectionActive === 5 ? (
                    <Traceability />
                  ) : sectionActive === 6 ? (
                    <Disposal />
                  ) : (
                    ''
                  )}
                </Card>
              </div>
            </div>
          </div>
          <div
            style={{
              position: isSmall ? 'fixed' : 'absolute',
              top: 5,
              left: isSmall ? '20px' : '60px',
              zIndex: '999'
            }}
          >
            {company && company.logo && company.logo.original ? (
              company.website ? (
                <>
                  <a
                    href={`${company.website}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Avatar
                      className={classes.roundedAvatar}
                      alt={`${company.name} logo`}
                      src={`${company.logo.original}`}
                    />
                  </a>
                </>
              ) : (
                <Avatar
                  className={classes.roundedAvatar}
                  alt={`${company.name} logo`}
                  src={`${company.logo.original}`}
                />
              )
            ) : null}
          </div>
        </SkeletonTheme>
      )
    }
  }
}
